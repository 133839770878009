<template>
  <v-card elevation="0">
    <v-toolbar>
      <v-btn icon class="hidden-xs-only" @click="onBack()">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title>
        {{ $t('machines.schedule.header') }}
        <div class="subheading">{{ machine.type }}: {{ machine.name }}</div>
      </v-toolbar-title>

      <template #extension>
        <v-tabs v-model="model" left>
          <v-tab>
            {{ $t('machines.schedule.template') }}
          </v-tab>
          <v-tab>
            {{ $t('machines.schedule.schedule') }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-tabs-items v-model="model">
      <v-tab-item>
        <schedule-template-tab :machine="machine" />
      </v-tab-item>
      <v-tab-item>
        <schedule-tab :machine="machine" />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
export default {
  components: {
    scheduleTemplateTab: () => import('./scheduleTemplateTab.vue'),
    scheduleTab: () => import('./scheduleTab.vue')
  },
  props: {
    machine: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      model: 'tab-template'
    };
  },
  activated() {
    // console.log("machineSchedule.activated(); machine: ", this.machine.name);
    this.model = 'tab-template';
  },
  methods: {
    onBack() {
      console.log('onBack');
      this.$emit('close');
    }
  }
};
</script>

<style></style>
