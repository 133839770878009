<template>
  <div class="Machines">
    <template>
      <v-card elevation="0" class="mx-2">
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('search')"
            single-line
            hide-details
            clearable
          />
        </v-card-title>

        <v-data-table
          dense
          :loading="loading"
          :loading-text="$t('loading')"
          :headers="headers"
          :items="machines"
          :items-per-page="20"
          :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, 50],
            'items-per-page-text': $t('table.per-page')
          }"
          :no-data-text="$t('table.no-data')"
          :search="search"
          :single-expand="true"
          :expanded.sync="expanded"
          show-expand
          @click:row="expandRow"
        >
          <template #top>
            <v-toolbar flat>
              <v-toolbar-title>{{ $t('machines.header') }}</v-toolbar-title>
              <v-divider class="mx-4" inset vertical />
              <v-spacer />
              <v-btn
                v-if="hasPermission('api.add_machine')"
                color="primary"
                dark
                class="mb-2"
                @click="onNewMachine"
              >
                {{ $t('machines.new') }}
              </v-btn>
            </v-toolbar>
          </template>
          <template #[`item.type`]="{ item }">
            {{ tType(item.type) }}
          </template>

          <template #[`item.actions`]="{ item }">
            <v-icon
              v-if="hasPermission('api.change_machine')"
              small
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-if="
                hasPermission('api.view_scheduletemplateevent') ||
                  hasPermission('api.view_scheduleevent')
              "
              small
              class="mr-2"
              @click="machineSchedule(item)"
            >
              mdi-calendar-weekend
            </v-icon>
            <v-icon
              v-if="hasPermission('api.delete_machine')"
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <template #expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <more-info :item="item" :headers="headers" />
            </td>
          </template>
        </v-data-table>
      </v-card>
    </template>
  </div>
</template>

<script>
import util from 'util';
import MachineMixin from '@/views/dashboard/components/machines/machineMixin';
import MachineService from '@/services/MachineService.js';

export default {
  components: {
    moreInfo: () => import('@/views/dashboard/components/machines/moreInfo.vue')
  },
  mixins: [MachineMixin],

  data: function() {
    return {
      loading: false,
      search: '',
      expanded: [],
      machines: [],
      scheduleDialog: false,
      editedIndex: -1,
      editedItem: null,
      defaultItem: {
        id: '',
        name: '',
        type: '',
        setup_time: '00:20',
        efficiency: 0,
        efficiency_unit: '',
        post_delay: '00:10',
        pre_delay: '00:10',
        waste: 0,
        setup_waste: 0
      }
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('machines.symbol'),
          align: 'start',
          value: 'symbol',
          width: '10%',
          sortable: true
        },
        {
          text: this.$t('machines.name'),
          value: 'name',
          sortable: true
        },
        {
          text: this.$t('machines.type'),
          value: 'type',
          sortable: true
        },
        {
          text: this.$t('actions'),
          value: 'actions',
          sortable: false
        }
      ];
    }
  },

  mounted() {
    this.$root.$on('machine_updated', this.onMachineUpdated);
    this.$root.$on('machine_added', this.onMachineAdded);
    this.loadItems();
  },
  beforeDestroy() {
    this.$root.$off('machine_updated', this.onMachineUpdated);
    this.$root.$off('machine_added', this.onMachineAdded);
  },
  methods: {
    async loadItems() {
      this.loading = true;
      try {
        this.machines = await MachineService.getMachines();
        console.log('machines:', this.machines);
      } catch (err) {
        this.showError(this, err);
      } finally {
        this.loading = false;
      }
    },
    editItem(item) {
      this.$emit('edit', Object.assign({}, item));
    },

    onNewMachine() {
      this.$emit('new');
    },

    onMachineAdded(machine) {
      console.log('onMachineAdded:', machine);
      this.machines.push(machine);
    },

    onMachineUpdated(machine) {
      console.log('onMachineUpdated:', machine);
      let index = this.machines.findIndex(c => c.id == machine.id);
      if (index != -1) {
        Object.assign(this.machines[index], machine);
      }
    },

    async deleteItem(item) {
      console.log('delete machine:', item);
      const res = await this.$swal({
        html: this.$t('machines.delete-confirmation'),
        showCancelButton: true,
        confirmButtonText: this.$t('yes'),
        cancelButtonText: this.$t('no')
      });

      if (res.isDismissed) {
        return;
      }

      const idx = this.machines.indexOf(item);

      try {
        let result = await MachineService.deleteMachine(item);
        console.log('result: ', result);
        this.machines.splice(idx, 1);
      } catch (err) {
        this.showError(this, err);
      }
    },

    machineSchedule(item) {
      // TODO: get rid of scheduleDialog
      this.$emit('schedule', item);
    },

    async scheduleCancel() {
      this.scheduleDialog = false;
    },

    async scheduleSave() {
      this.scheduleDialog = false;
    },

    expandRow(item) {
      this.expanded = item === this.expanded[0] ? [] : [item];
    }
  }
};
</script>
