import request from './request'

export default {
  getMachineTypes() {
    let url = '/api/machine_types/'

    return request.allPages(url, url => {
      // console.log("url:", url)
      return request.request(url, 'get', {}, {})
    })
  },

  async postMachine(machine) {
    console.log('machine: ', machine)
    const result = await request.request('/api/machines/', 'post', machine, {})
    console.log('result: ', result)
    return result
  },

  getMachines() {
    let url = `/api/machines/`

    return request.allPages(url, url => {
      // console.log("url:", url)
      return request.request(url, 'get', {}, {})
    })
  },

  async patchMachine(machine) {
    console.log('machine: ', machine)
    let id = machine.id
    const result = await request.request(
      `/api/machines/${id}/`,
      'patch',
      machine,
      {}
    )

    console.log('result: ', result)
    return result
  },

  async deleteMachine(machine) {
    console.log('machine: ', machine)
    let id = machine.id
    const result = await request.request(
      `/api/machines/${id}/`,
      'delete',
      {},
      {}
    )

    console.log('result: ', result)
    return result
  },

  getMachineWasteRanges(machine) {
    let url = `/api/machines/${machine.id}/waste-ranges`

    return request.allPages(url, url => {
      // console.log("url:", url)
      return request.request(url, 'get', {}, {})
    })
  }
}
