<template>
  <v-card class="" dense elevation="0">
    <v-card-title>
      <v-btn icon @click="$emit('close')">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span class="headline">{{ title }}</span>
    </v-card-title>

    <v-card-text>
      <v-container>
        <validation-observer ref="observer">
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <validation-provider
                v-slot="{ errors }"
                rules="required|min:1"
                :name="$t('machines.name')"
              >
                <v-text-field
                  ref="name"
                  v-model="item.name"
                  :label="$t('machines.name')"
                  dense
                  :error-messages="errors"
                  @keydown.esc="onCancel"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <validation-provider
                v-slot="{ errors }"
                rules="required|min:1|max:2"
                :name="$t('machines.symbol')"
              >
                <v-text-field
                  ref="symbol"
                  v-model="item.symbol"
                  :label="$t('machines.symbol')"
                  dense
                  :error-messages="errors"
                  @keydown.esc="onCancel"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <validation-provider
                v-slot="{ errors }"
                rules="required|min:1"
                :name="$t('machines.type')"
              >
                <v-select
                  v-model="item.type"
                  :items="typesToSelect"
                  :menu-props="{ maxHeight: '400' }"
                  :label="$t('machines.type')"
                  :hint="$t('select-hint')"
                  persistent-hint
                  dense
                  :error-messages="errors"
                  @keydown.esc="onCancel"
                />
              </validation-provider>
            </v-col>
            <v-col cols="6" sm="3" md="3">
              <div class="form-group form-check">
                <h6>{{ $t('machines.setup-time') }}</h6>
                <vue-timepicker
                  v-model="item.setup_time"
                  format="HH:mm:ss"
                  @keydown.esc="onCancel"
                />
              </div>
            </v-col>
            <v-col cols="6" sm="3" md="3">
              <div class="form-group form-check">
                <h6>{{ $t('machines.pause-before-start') }}</h6>
                <vue-timepicker
                  v-model="item.pre_delay"
                  format="HH:mm:ss"
                  @keydown.esc="onCancel"
                />
              </div>
            </v-col>
            <v-col cols="6" sm="3" md="3">
              <div class="form-group form-check">
                <h6>{{ $t('machines.pause-after-completion') }}</h6>
                <vue-timepicker
                  v-model="item.post_delay"
                  format="HH:mm:ss"
                  @keydown.esc="onCancel"
                />
              </div>
            </v-col>
            <v-col cols="6" sm="3" md="3">
              <validation-provider
                v-slot="{ errors }"
                :rules="{ decimal: true }"
                :label="$t('machines.efficiency')"
              >
                <v-text-field
                  v-model="item.efficiency"
                  :label="$t('machines.efficiency')"
                  dense
                  :error-messages="errors"
                  @keydown.esc="onCancel"
                />
              </validation-provider>
            </v-col>
            <v-col cols="6" sm="3" md="3">
              <validation-provider
                v-slot="{ errors }"
                rules="required|min:1"
                :name="$t('unit')"
              >
                <v-select
                  v-model="item.efficiency_unit"
                  :items="units"
                  :menu-props="{ maxHeight: '400' }"
                  :label="$t('unit')"
                  :hint="$t('select-hint')"
                  persistent-hint
                  dense
                  :error-messages="errors"
                  @keydown.esc="onCancel"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <waste-ranges v-model="item.waste_ranges" @error="onError" />
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <validation-provider
                v-slot="{ errors }"
                :rules="{ numeric: true, min_value: 0 }"
                :name="$t('machines.setup-waste')"
              >
                <v-text-field
                  v-model="item.setup_waste"
                  :label="$t('machines.setup-waste')"
                  dense
                  :error-messages="errors"
                  @keydown.esc="onCancel"
                />
              </validation-provider>
            </v-col>

            <v-col v-for="(group, i) in pgs" :key="i" cols="12" sm="6">
              <v-select
                v-model="selectedProcesses[group.name]"
                :items="processesToSelect.get(group.id)"
                item-value="id"
                item-text="name"
                multiple
                chips
                :hint="groupNames[i]"
                persistent-hint
                @keydown.esc="onCancel"
              />
            </v-col>
          </v-row>
        </validation-observer>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn color="blue darken-1" text @click="onCancel()">
        {{ $t('cancel') }}
      </v-btn>
      <v-btn color="blue darken-1" text @click="onSave()">
        {{ $t('save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';
import MachineService from '@/services/MachineService.js';
import WasteRanges from './wasteRanges.vue';
import MachineMixin from '@/views/dashboard/components/machines/machineMixin';
import _ from 'lodash';

export default {
  components: {
    VueTimepicker,
    WasteRanges
  },
  mixins: [MachineMixin],
  props: {
    value: {
      type: Object,
      required: true
    },
    pgs: {
      type: Array,
      required: true
    },
    types: {
      type: Array,
      required: true
    }
  },
  data: function() {
    return {
      item: Object.assign({}, this.value),
      newAddresses: new Set(),
      selectedProcesses: {}
    };
  },
  computed: {
    isNew() {
      return !(this.item.id > 0);
    },
    title() {
      if (this.isNew) {
        return this.$t('machines.new');
      }
      return this.$t('machines.edit', { machine: this.item.name });
    },
    units() {
      return [
        { value: 'sheet', text: this.$t('units.sheet') },
        { value: 'box', text: this.$t('units.box') }
      ];
    },
    typesToSelect() {
      return this.types.map(t => {
        return {
          text: this.$t(`machines.types.${t.name}`),
          value: t.name
        };
      });
    },
    processesToSelect() {
      let ret = new Map();
      for (const group of this.pgs) {
        ret.set(
          group.id,
          group.processes.map(p => {
            return {
              id: p.id,
              name: this.tProcessName(group.name, p.name)
            };
          })
        );
      }
      return ret;
    },
    groupNames() {
      return this.pgs.map(group => this.tProcessGroupName(group.name));
    }
  },
  watch: {
    value: {
      handler() {
        this.item = this.value;
      },
      deep: true
    },
    item: {
      handler() {
        this.$emit('input', this.item);
      },
      deep: true
    },
    pgs: {
      handler() {
        console.log('pgs changed:', this.pgs);
        this.initProcessStorage();
      },
      deep: true
    }
  },
  activated() {
    this.selectedProcesses = {};
    this.initProcessStorage();
    this.focusInput();
  },
  methods: {
    async onCancel() {
      this.$refs.observer.reset();
      this.$emit('close');
    },

    async onSave() {
      const result = await this.$refs.observer.validate();
      if (!result) return;

      try {
        let obj = Object.assign({}, this.item);

        obj.processes = [];
        for (const [, processes] of Object.entries(this.selectedProcesses)) {
          obj.processes = obj.processes.concat(processes);
        }
        console.log('save machine:', obj);

        if (!this.isNew) {
          let result = await MachineService.patchMachine(obj);
          console.log('result: ', result);
          this.$root.$emit('machine_updated', result.data); // use central event hub to notify siblign container
        } else {
          let result = await MachineService.postMachine(obj);
          console.log('result: ', result);
          this.$root.$emit('machine_added', result.data); // use central event hub to notify siblign container
        }
        this.$emit('close');
      } catch (err) {
        this.showError(this, err);
      }
    },

    initProcessStorage() {
      let processIdToGroupName = {};
      this.pgs.forEach(g => {
        this.selectedProcesses[g.name] = [];
        g.processes.forEach(p => {
          processIdToGroupName[p.id] = g.name;
        });
      });
      this.item?.processes?.forEach(p => {
        let group = processIdToGroupName[p];
        this.selectedProcesses[group].push(p);
      });

      this.pgs.forEach(g => {
        this.selectedProcesses[g.name] = _.uniq(this.selectedProcesses[g.name]);
      });
    },

    focusInput() {
      this.$refs.name.focus();
    },

    onError(err) {
      this.showError(this, err);
    }
  }
};
</script>

<style></style>
