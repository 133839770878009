<template>
  <v-card elevation="0">
    <v-card-title>{{ $t('machines.waste-ranges.title') }}</v-card-title>
    <v-data-table
      dense
      :loading="$t('loading')"
      :loading-text="$t('loading')"
      :headers="headers"
      :items="items"
      :footer-props="{
        'items-per-page-text': $t('table.per-page')
      }"
      :no-data-text="$t('table.no-data')"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template #top>
        <v-row align-self="end">
          <v-col class="text-right">
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="accent"
              @click="onNewItem"
            >
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-dialog v-model="editDialog" max-width="300px">
          <validation-observer ref="editobserver">
            <v-card elevation="0">
              <v-col cols="12" sm="6" md="6">
                <validation-provider
                  v-slot="{ errors }"
                  :rules="{ numeric: true, min_value: 1 }"
                  :name="$t('machines.waste-ranges.limit')"
                >
                  <v-text-field
                    ref="limit"
                    v-model="editedItem.limit"
                    :label="$t('machines.waste-ranges.limit')"
                    dense
                    :error-messages="errors"
                    @keydown.esc="onCancel"
                  />
                </validation-provider>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <validation-provider
                  v-slot="{ errors }"
                  :rules="{ decimal: true }"
                  :name="$t('machines.waste-ranges.waste')"
                >
                  <v-text-field
                    v-model="editedItem.waste"
                    :label="$t('machines.waste-ranges.waste')"
                    dense
                    :error-messages="errors"
                    @keydown.esc="onCancel"
                  />
                </validation-provider>
              </v-col>

              <v-card-actions>
                <v-spacer />
                <v-btn color="blue darken-1" text @click="onCancel">
                  {{ $t('cancel') }}
                </v-btn>
                <v-btn color="blue darken-1" text @click="saveItem">
                  {{ $t('ok') }}
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </validation-observer>
        </v-dialog>
      </template>
      <template #[`item.actions`]="{ item }">
        <v-icon
          v-if="hasPermission('api.change_machine')"
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>

        <v-icon
          v-if="hasPermission('api.change_machine')"
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
      required: false
    }
  },

  data: function() {
    return {
      items: [...this.value],
      editDialog: false,
      editedIndex: -1,
      editedItem: {},
      loading: false,
      defaultItem: {
        limit: 0,
        waste: 0.0
      },
      sortBy: 'limit',
      sortDesc: false
    };
  },

  computed: {
    isNew() {
      return !(this.editedItem.id > 0);
    },
    headers() {
      return [
        {
          text: this.$t('machines.waste-ranges.limit'),
          value: 'limit',
          sortable: true,
          align: 'right'
        },
        {
          text: this.$t('machines.waste'),
          value: 'waste',
          sortable: false,
          align: 'right'
        },
        {
          text: this.$t('actions'),
          value: 'actions',
          sortable: false
        }
      ];
    }
  },

  watch: {
    value: {
      handler() {
        this.items = [...this.value];
      },
      deep: true
    }
  },

  activated() {
    this.machine?.id && this.load();
  },

  methods: {
    async deleteItem(item) {
      console.log('delete waste range item:', item);
      const res = await this.$swal({
        html: this.$t('machines.waste-ranges.delete-confirmation'),
        showCancelButton: true,
        confirmButtonText: this.$t('yes'),
        cancelButtonText: this.$t('no')
      });

      if (res.isDismissed) {
        return;
      }

      const idx = this.items.indexOf(item);
      this.items.splice(idx, 1);
      this.$emit('update', this.items);
    },

    onNewItem() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editDialog = true;
      setTimeout(() => {
        this.$refs.limit?.focus();
      }, 200);
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = this.items[this.editedIndex];
      this.editDialog = true;
      setTimeout(() => {
        this.$refs.limit?.focus();
      }, 200);
    },

    onCancel() {
      this.editDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async saveItem() {
      const result = await this.$refs.editobserver.validate();
      if (!result) return;

      this.editDialog = false;
      if (this.isNew) {
        console.log('isNew');
        this.items.push(this.editedItem);
      }
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.$emit('update', this.items);
    }
  }
};
</script>

<style></style>
