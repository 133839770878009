import request from './request'

export default {
  getProcessGroups() {
    let url = `/api/process_groups/`

    return request.allPages(url, url => {
      // console.log("url:", url)
      return request.request(url, 'get', {}, {})
    })
  }
}
