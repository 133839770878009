const mixin = {
  methods: {
    tType: function(typeName) {
      const key = `machines.types.${typeName}`;
      if (this.$te(key)) {
        return this.$t(key);
      }
      return typeName;
    },
    tProcessGroupName: function(groupName) {
      const key = `process-group.${groupName}`;
      if (this.$te(key)) {
        return this.$t(key);
      }
      return groupName;
    },
    tProcessName: function(groupName, processName) {
      const k = `${groupName || ''}/${processName || ''}`;
      const key = `process.${k}`;
      if (this.$te(key)) {
        return this.$t(key);
      }
      return k;
    }
  }
};
export default mixin;
